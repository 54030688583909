import React, { Fragment } from "react";
import { Navigate, Outlet } from "react-router-dom";

import useUserStore from "../store/useUserStore";

const ProtectedAdminRoutes = (props) => {
  return <ProtectedRoutes {...props} admin />;
};

const ProtectedRoutes = ({ children, admin, manager }) => {
  const forAdmin = !!admin;
  const forManager = !!manager;
  const { userProfile } = useUserStore();

  if (!userProfile || (forAdmin && !userProfile?.isAdmin) || (forManager && (!userProfile?.isAdmin || !userProfile?.isManageer)))
    return <Navigate to="/" />;

  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export { ProtectedRoutes, ProtectedAdminRoutes };
