import React, { useState, useEffect } from "react";
import { FormControl, InputGroup, Button } from "react-bootstrap";

import styles from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchBar = ({ onSearch, className }) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearch(query);
    }, 500);

    return () => clearTimeout(timer);
  }, [query, onSearch]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <InputGroup className={className}>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" value={query} onChange={handleInputChange} />
      <Button variant="outline-secondary" className={styles.btn}>
        X
      </Button>
      <Button variant="outline-secondary" className={`${styles.btn} ${styles.lastBtn}`}>
        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className={styles.search} />
      </Button>
    </InputGroup>
  );
};

export default SearchBar;
