import React from "react";
import { Dropdown } from "react-bootstrap";

export const priorityList = [
  { name: "low", title: "Low", color: "green" },
  { name: "medium", title: "Medium", color: "yellow" },
  { name: "high", title: "High", color: "orange" },
  { name: "critical", title: "Urgent", color: "red" },
];

export const PrioritySelector = ({ onChange = () => {}, defaultPriority = "low", style = {}, className = "" }) => {
  const [priority, setPriority] = React.useState(priorityList.find((pr) => pr.name === defaultPriority.toLowerCase())?.name || "low");

  const handleChange = (priority) => {
    console.log(priority);
    setPriority(priority);
    onChange(priority);
  };

  const ChangePriorityToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      style={{
        width: "120px",
        padding: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50px",
        border: `1px solid ${priorityList.find((pr) => pr.name === priority)?.color}` || "var(--brand-text)",
        fontWeight: "300",
        cursor: "pointer",
        fontSize: "0.5rem",
        color: "var(--brand-text)",
        "&:hover": {
          color: priorityList.find((pr) => pr.name === priority)?.color || "var(--brand-text)",
        },
        ...style,
      }}
      className={className}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  ChangePriorityToggle.displayName = "ChangePriorityToggle";

  return (
    <Dropdown
      id={`dropdown-priority-${priority}`}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "300",
        cursor: "pointer",
      }}
      onSelect={handleChange}
      onClick={(e) => e.stopPropagation()}
    >
      <Dropdown.Toggle as={ChangePriorityToggle} id="dropdown-custom-components">
        {priorityList.find((pr) => pr.name === priority).title.toUpperCase()}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {priorityList.map((pr) => (
          <Dropdown.Item key={pr.name} eventKey={pr.name} style={{ color: pr.color, borderColor: pr.color }}>
            {pr.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
