// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.useNotification_notification__a4VTF {
  transform: translateX(150%);
  transition: transform 0.5s ease-in-out;
  width: 100%;
  max-width: 980px;
  min-width: 380px;
}

.useNotification_notificationActive__GTPeC {
  transform: translateX(0%);
}

.useNotification_error__9eNJm {
  background-color: var(--brand-error-color);
  color: var(--text-color);
}

.useNotification_success__l9fb2 {
  background-color: var(--brand-success-color);
}
`, "",{"version":3,"sources":["webpack://./src/hooks/useNotification/index.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,sCAAsC;EACtC,WAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0CAA0C;EAC1C,wBAAwB;AAC1B;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".notification {\n  transform: translateX(150%);\n  transition: transform 0.5s ease-in-out;\n  width: 100%;\n  max-width: 980px;\n  min-width: 380px;\n}\n\n.notificationActive {\n  transform: translateX(0%);\n}\n\n.error {\n  background-color: var(--brand-error-color);\n  color: var(--text-color);\n}\n\n.success {\n  background-color: var(--brand-success-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `useNotification_notification__a4VTF`,
	"notificationActive": `useNotification_notificationActive__GTPeC`,
	"error": `useNotification_error__9eNJm`,
	"success": `useNotification_success__l9fb2`
};
export default ___CSS_LOADER_EXPORT___;
