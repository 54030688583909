import React, { useState } from "react";
import { Stack, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import BrandButton from "../../components/BrandButton";
import BrandButtonAnimated from "../../components/BrandButtonAnimated";
import TicketsList from "../../components/Ticket/TicketsList";
import links from "../../router/links";

const TicketsPage = () => {
  const navigate = useNavigate();
  const [showHistory, setShowHistory] = useState(false);
  const buttonWidth = "250px";
  return (
    <Container fluid>
      <Stack direction="horizontal" gap={5} className="d-flex w-100 justify-content-center align-items-center p-2">
        <BrandButton
          onClick={() => {navigate(links.createTicket)}}
          style={{
            minWidth: buttonWidth,
          }}
        >
          Create a new ticket
        </BrandButton>
        <BrandButtonAnimated
          onClick={() => setShowHistory((prevState) => !prevState)}
          style={{
            minWidth: buttonWidth,
          }}
        >
          {showHistory ? "Show opened tickets" : "Show history"}
        </BrandButtonAnimated>
      </Stack>
      <TicketsList showHistory={showHistory} />
    </Container>
  );
};

export default TicketsPage;
