// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaginationBar_pagination__oLLY2 {
  justify-content: center;
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.1rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--brand-second-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: 0;
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--brand-second-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--brand-second-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: none;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: var(--brand-primary-color);
  --bs-pagination-active-border-color: var(--brand-primary-color);
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/PaginationBar/index.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,iCAAiC;EACjC,iCAAiC;EACjC,+BAA+B;EAC/B,gDAAgD;EAChD,qCAAqC;EACrC,+BAA+B;EAC/B,oDAAoD;EACpD,sDAAsD;EACtD,sDAAsD;EACtD,+CAA+C;EAC/C,0DAA0D;EAC1D,sDAAsD;EACtD,gDAAgD;EAChD,sCAAsC;EACtC,kCAAkC;EAClC,qDAAqD;EACrD,+DAA+D;EAC/D,yDAAyD;EACzD,mDAAmD;EACnD,6DAA6D;AAC/D","sourcesContent":[".pagination {\n  justify-content: center;\n  --bs-pagination-padding-x: 0.5rem;\n  --bs-pagination-padding-y: 0.1rem;\n  --bs-pagination-font-size: 1rem;\n  --bs-pagination-color: var(--brand-second-color);\n  --bs-pagination-bg: var(--bs-body-bg);\n  --bs-pagination-border-width: 0;\n  --bs-pagination-border-color: var(--bs-border-color);\n  --bs-pagination-border-radius: var(--bs-border-radius);\n  --bs-pagination-hover-color: var(--brand-second-color);\n  --bs-pagination-hover-bg: var(--bs-tertiary-bg);\n  --bs-pagination-hover-border-color: var(--bs-border-color);\n  --bs-pagination-focus-color: var(--brand-second-color);\n  --bs-pagination-focus-bg: var(--bs-secondary-bg);\n  --bs-pagination-focus-box-shadow: none;\n  --bs-pagination-active-color: #fff;\n  --bs-pagination-active-bg: var(--brand-primary-color);\n  --bs-pagination-active-border-color: var(--brand-primary-color);\n  --bs-pagination-disabled-color: var(--bs-secondary-color);\n  --bs-pagination-disabled-bg: var(--bs-secondary-bg);\n  --bs-pagination-disabled-border-color: var(--bs-border-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `PaginationBar_pagination__oLLY2`
};
export default ___CSS_LOADER_EXPORT___;
