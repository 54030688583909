import React, { useState, useEffect, useMemo } from "react";
import useTickets from "../../hooks/useTickets";
import { useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { TicketContent } from "./TicketItem";

const SingleTicket = () => {
  const { ticketId } = useParams();
  const { fetchTicket } = useTickets();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        setTicket(await fetchTicket(ticketId));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  const memoizedTicket = useMemo(() => ticket, [ticket]);

  if (loading || !memoizedTicket) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Accordion.Item>
      <TicketContent tk={memoizedTicket} />
    </Accordion.Item>
  );
};

export default SingleTicket;
