import React, { useState } from "react";

import { Row, Stack, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./index.module.css";
import MarkdownContent from "../MarkdownContent";
import BrandLine from "../BrandLine";

const Accordion = ({ title, subtitle, children, style = {}, className = "" }) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack
      direction="horizontal"
      style={{
        position: "relative",
        ...style,
      }}
      className={`${styles.card} ${className}`}
      gap={5}
      key={title}
      onClick={() => setOpen(!open)}
    >
      <Row className={styles.content}>
        <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <h5 className="">{title}</h5>
            <MarkdownContent>{subtitle}</MarkdownContent>
          </div>
          <FontAwesomeIcon
            icon={`fa-solid fa-angles-${open ? "up" : "down"}`}
            />
        </div>
        <BrandLine style={{ marginTop: "0.5rem" }} reverse />
        <Collapse
          in={open}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className={styles.customCardDescription}>{children}</div>
        </Collapse>
      </Row>
    </Stack>
  );
};

export default Accordion;
