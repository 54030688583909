import { create } from "zustand";
import apiRequest from "../handlers/api";

const useUserStore = create((set) => ({
  // Состояние аккаунта пользователя
  userAccount: null,
  setUserAccount: (userAccount) => set({ userAccount }),

  // Состояние профиля пользователя
  userProfile: null,
  fetchUserProfile: async () => {
    try {
      const user = await apiRequest("/user");
      if (!user || user.error) {
        set({ userProfile: null });
        return;
      }
      user.isAdmin = user?.roles?.includes("admin") || false;
      user.isManager = user?.roles?.some((v) => ["admin", "manager"].includes(v)) || false;
      set({ userProfile: user });
    } catch (error) {
      console.error("Failed to fetch user profile:", error);
    }
  },
  logout: async () => {
    try {
      await apiRequest("/auth/signout");
      set({ userProfile: null });
    } catch (error) {
      console.error("Failed to logout:", error);
    }
  },

  // Состояние тикетов пользователя
  userTickets: [],
  setUserTickets: (userTickets) => set({ userTickets }),

  // Состояние разделов помощи
  helpSections: [],
  setHelpSections: (helpSections) => set({ helpSections }),
}));

export default useUserStore;
