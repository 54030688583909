import React from "react";
import BrandButtonAnimated from "../BrandButtonAnimated";
import microsoftLogo from "../../static/images/microsoftLogo.png";
import { Image } from "react-bootstrap";
// import useUserData from "../../hooks/useUserData";

export const MicrosoftLoginButton = ({ style }) => {
  // const { loginUser } = useUserData();

  const loginHandler = () => {
    window.location.href = "/api/v1/auth/microsoft";
  };

  return (
    <BrandButtonAnimated onClick={loginHandler} style={style}>
      <Image src={microsoftLogo} width={18} height={18} />
      Sign in with Microsoft
    </BrandButtonAnimated>
  );
};
