import {useState} from 'react';
import style from "./style.module.css";
import { Container } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import useThemeStore from "../../store/themeStore";

library.add(fas);

const InputField = ({ label, value = "", icon = <></>, onChange = () => {}, extended = false, placeholder = "", disabled = false, editable = false }) => {
  const isWhiteMode = useThemeStore((state) => state.isWhiteMode);

  const [editEnable, setEditEnable] = useState(!editable);

  const [oldValue, setOldValue] = useState(value);

  const handleEditComplited = (e) => { 
    if(editEnable && typeof(editable) == "function"){
      editable(e)
    }
    if(!editEnable) {
      setOldValue(value);
    }

    setEditEnable(!editEnable);
  }

  const handleEditCancel = () => {
    onChange({target: { value: oldValue }})
    setEditEnable(!editEnable);
  }


  return (
    <Container fluid className={style.wrapper}>
      <div className={style.iconPlaceholder}>
        <FontAwesomeIcon icon={`fa-solid ${icon}`} size="2x" />
      </div>
      <div className={`${style.inputContainer} ${isWhiteMode ? style.inputContainerWhiteMode : ""}`}>
        <label htmlFor="name" className={`${style.label} ${isWhiteMode ? style.labelWhiteMode : ""}`}>
          {label}
        </label>
        {extended ? (
          <textarea
            rows={4}
            id={`${label}_input`}
            placeholder={placeholder}
            onChange={editEnable ? onChange : () => {}}
            value={value}
            disabled={disabled}
            className={`${style.input} ${isWhiteMode ? style.inputWhiteMode : ""}`}
          />
        ) : (
          <input
            type="text"
            disabled={disabled}
            id={`${label}_input`}
            value={value}
            onChange={editEnable ? onChange : () => {}}
            placeholder={placeholder}
            className={`${style.input} ${isWhiteMode ? style.inputWhiteMode : ""}`}
          />
        )}
        <div style={{
          display: "flex",
          gap: "0.5rem",
          cursor: "pointer",
        }}>
          { editable && !disabled && <FontAwesomeIcon color={editEnable ? "green" : "white"} icon={`fa-solid ${ editEnable ? "fa-check" : "fa-pen"}`} onClick={handleEditComplited} />}
          { editable && editEnable && <FontAwesomeIcon color="red" icon="fa-solid fa-xmark" onClick={handleEditCancel} />}
        </div>
      </div>
    </Container>
  );
};

export default InputField;
