import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CodeBlock = ({ children }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(children);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false); // Возвращаемся к исходной иконке через 3 секунды
      }, 3000);

      return () => clearTimeout(timer); // Очищаем таймер при размонтировании компонента
    }
  }, [copied]);

  return (
    <div style={{ position: "relative" }}>
      <div
        onClick={copyToClipboard}
        style={{
          position: "absolute",
          top: "0.5rem",
          right: "0.5rem",
          cursor: "pointer",
          color: copied ? "#11ed43" : "#ccc", // Зеленый цвет при копировании
          transition: "color 0.3s ease",
        }}
      >
        {copied ? (
          <FontAwesomeIcon icon="fa-solid fa-clipboard" size={"1rem"} />
        ) : (
          <FontAwesomeIcon icon="fa-solid fa-clipboard-ckeck" size={"1rem"} />
        )}
      </div>
      <pre style={{ margin: "0", overflowX: "auto", whiteSpace: "pre-wrap" }}>
        <code>{children}</code>
      </pre>
    </div>
  );
};

const MarkdownContent = ({ children }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        code({ node, inline, className, children, ...props }) {
          return !inline ? (
            <CodeBlock>{children}</CodeBlock>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default MarkdownContent;
