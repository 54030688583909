// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_btn__eztSn {
  border: 1px solid var(--brand-light-gray) !important;
}

.SearchBar_btn__eztSn:hover,
.SearchBar_btn__eztSn:focus,
.SearchBar_btn__eztSn:active,
.SearchBar_btn__eztSn:visited {
  border: 1px solid var(--brand-light-gray) !important;
}

.SearchBar_lastBtn__iESR7 {
  border-radius: 25px;
}

.SearchBar_search__UMM0K {
    vertical-align: -2px;
    
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/index.module.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;AACtD;;AAEA;;;;EAIE,oDAAoD;AACtD;;AAEA;EACE,mBAAmB;AACrB;;AAEA;IACI,oBAAoB;;AAExB","sourcesContent":[".btn {\n  border: 1px solid var(--brand-light-gray) !important;\n}\n\n.btn:hover,\n.btn:focus,\n.btn:active,\n.btn:visited {\n  border: 1px solid var(--brand-light-gray) !important;\n}\n\n.lastBtn {\n  border-radius: 25px;\n}\n\n.search {\n    vertical-align: -2px;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `SearchBar_btn__eztSn`,
	"lastBtn": `SearchBar_lastBtn__iESR7`,
	"search": `SearchBar_search__UMM0K`
};
export default ___CSS_LOADER_EXPORT___;
