import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";

import useTickets from "../../hooks/useTickets";
import TicketsListWithMoreButton from "./TicketsListWithMoreButton";
import useUserStore from "../../store/useUserStore";

import Accordion from "../Accordion";

const TicketsList = ({ showHistory }) => {
  const { userTickets } = useUserStore();
  const [ticketList, setTicketList] = useState([]);

  const { fetchTickets, isloading } = useTickets();

  useEffect(() => {
    const fetch = async () => {
      await fetchTickets();
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const priority = {
      low: 1,
      medium: 2,
      high: 3,
      critical: 4,
    };
    setTicketList(
      showHistory
        ? userTickets.flatMap((tk) => tk.closed).sort((a, b) => new Date(b.close_time) - new Date(a.close_time))
        : userTickets
            .flatMap((tk) => tk.open)
            .sort((a, b) => {
              if (priority[b.priority] !== priority[a.priority]) {
                return priority[b.priority] - priority[a.priority];
              } else {
                return new Date(a.create_time) - new Date(b.create_time);
              }
            })
    );
  }, [showHistory, userTickets]);

  if (isloading) {
    return (
      <Container fluid className="text-center p-5">
        <Spinner />
      </Container>
    );
  }

  if (userTickets?.length === 0) {
    return (
      <Container fluid className="text-center p-5">
        No tickets
      </Container>
    );
  }

  return (
    <>
      {userTickets?.length > 1 ? (
        <div>
          {userTickets.map((user, index) => {
            if ((showHistory && user.closed.length > 0) || (!showHistory && user.open.length > 0)) {
              return (
                <Accordion title={user.name} subtitle={`Tickets count ${showHistory ? user.closed.length : user.open.length}`} style={{backgroundColor: "transparent"}} key={index}>
                  <TicketsListWithMoreButton
                    listTitle=" "
                    showTicketsList={showHistory ? user.closed : user.open}
                    showHistory={showHistory}
                  />
                </Accordion>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      ) : (
        <TicketsListWithMoreButton showTicketsList={ticketList} showHistory={showHistory} />
      )}
    </>
  );
};

export default TicketsList;
