import React, { useState } from "react";
import { Container, Stack, Row, Col } from "react-bootstrap";

import styles from "./tools.module.css";
import InputField from "../InputField/InputField";
import BrandButton from "../BrandButton";

import useUserStore from "../../store/useUserStore";
import useTickets from "../../hooks/useTickets";
import { useNotification } from "../../hooks/useNotification";

import { useNavigate } from "react-router-dom";
import links from "../../router/links";

const AccountBanForm = () => { 
  const { showNotification } = useNotification();
  const { userProfile } = useUserStore();
  const { openTicket } = useTickets();

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');


  const handleSubmit = async (event) => {

    event.preventDefault();
    const text = `
Email: ${email},
Reason: ${reason},
    `;

    
    const newTicketData = {
      state: "new",
      title: `Ban request ${email} account` ,
      text: text,
      userId: userProfile.id,
      priority: "high",
      conversation_history: [
        {
          timestamp: new Date(),
          senderId: userProfile.id,
          message: text,
          senderName: userProfile.displayName,
        },
      ],
    };
    const result = await openTicket(newTicketData);

    if (result?.error) {
      showNotification({
        title: "New ticket creation error",
        message: result.error,
      });
      return;
    }

    try {
      setEmail('');
      setReason('');
      showNotification({
        title: "New ticket created.",
        type: "success",
        message: "Your request has been sent",
      });
      navigate(links.tickets)
    } catch (error) {
      showNotification({
        title: "New ticket creation error",
        message: error,
      });
    }
  };
  
  return(
    <Container fluid className="d-flex flex-column align-items-center gap-4">
      <Row className="pt-4">
        <h1>Account Creation Form</h1>
      </Row>
      <Row className={styles.wrapper}>
        <Col className={styles.colContent} xs={12} lg="auto">
          <Stack gap={3} className="pt-5">
            <InputField
              label="Email of the person you want to block"
              value={email}
              icon="fa-envelope-open"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="sale@ae-solar.com"
            />
            <InputField
              label="Reason for the ban"
              value={reason}
              icon="fa-question"
              onChange={(e) => setReason(e.target.value)}
              placeholder="Dismissed"
            />
          </Stack>
        </Col>
      </Row>
      <Row className="pt-1">
        <BrandButton className={styles.createButton} onClick={handleSubmit} > Create a ticket </BrandButton>
      </Row>
    </Container>
  )
}

export default AccountBanForm;