import React from "react";
import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { NavLink, useLocation } from "react-router-dom";
import { ButtonGroup, Container } from "react-bootstrap";

import styles from "./index.module.css";
import links from "../../router/links";
import useUserStore from "../../store/useUserStore.js";

const MenuItem = ({ title, link, icon = "arrow-right", children = [] }) => {
  if (!Array.isArray(children)) {
    children = [children];
  }

  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      key={title?.replace(/[^\d\w\S]/gm, "")}
      style={{
        position: "relative",
        width: "100%",
      }}
    >
      <NavLink
        to={children.length > 0 ? "#" : link}
        className={`${styles.sideBtn} ${location?.pathname === link && styles.active}`}
        onClick={() => children.length > 0 && setIsExpanded((prevState) => !prevState)}
      >
        {icon && <FontAwesomeIcon icon={`fa-solid fa-${icon}`} size="xl" />}
        {title}
      </NavLink>
      {isExpanded && (
        <div className={styles.subMenu}>
          {children.map((child) => (
            <MenuItem key={child.props.title} {...child.props} />
          ))}
        </div>
      )}
    </div>
  );
};

export default function SideMemu() {
  const { userProfile } = useUserStore();

  return (
    <Container className="m-0 p-0">
      <ButtonGroup vertical aria-label="Menu" className={styles.sideMenu}>
        {userProfile && (
          <>
            <MenuItem title="Chat help" link={links.chat} icon="comment" />
            <MenuItem title="Tickets" link={links.tickets} icon="bugs" />
            {/* <MenuItem title="Dashboard" link={links.dashboard} icon="gauge-high" /> */}
            <MenuItem title="Tools" icon="hammer">
              <MenuItem title="QR Gen." link={links.qrCodeGen} icon="qrcode" />
              <MenuItem title="Signature Gen." link={links.signatureGen} icon="signature" />
              <MenuItem title="Create Account" link={links.accountCreationForm} icon="plus" />
              <MenuItem title="Ban Account" link={links.accountBanForm} icon="minus" />
            </MenuItem>
          </>
        )}
        <MenuItem title="Help center" link={links.helpcenter} icon="circle-question" />

        <div className={styles.themeToggle}>{/* <ThemeToggle /> */}</div>
      </ButtonGroup>
    </Container>
  );
}
