// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfilePage_wrapper__Uplq5 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.ProfilePage_colContent__KR-\\+A {
  min-width: 512px;
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}

.ProfilePage_inputWithIcons__vk9Q0 {
  display: flex;
  align-items: center;
  position: relative;
}

.ProfilePage_inputWithIcons__vk9Q0 input {
  flex-grow: 1; /* Заставляем input занимать доступное пространство */
  padding-right: 50px; /* Резервируем место для иконок */
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProfilePage/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY,EAAE,qDAAqD;EACnE,mBAAmB,EAAE,iCAAiC;AACxD","sourcesContent":[".wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  justify-content: center;\n}\n\n.colContent {\n  min-width: 512px;\n  flex-grow: 1;\n  display: flex;\n  align-items: flex-start;\n}\n\n.inputWithIcons {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.inputWithIcons input {\n  flex-grow: 1; /* Заставляем input занимать доступное пространство */\n  padding-right: 50px; /* Резервируем место для иконок */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ProfilePage_wrapper__Uplq5`,
	"colContent": `ProfilePage_colContent__KR-+A`,
	"inputWithIcons": `ProfilePage_inputWithIcons__vk9Q0`
};
export default ___CSS_LOADER_EXPORT___;
