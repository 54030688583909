import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const BooleanModal = ({
  children,
  onAccept,
  onDecline,
  title,
  description,
  trueText,
  falseText,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const handleFalse = () => {
    setShow(false);
    onDecline && onDecline();
  };
  const handleTrue = () => {
    setShow(false);
    onAccept && onAccept();
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <span {...props}>
      <span onClick={handleShow} style={{ cursor: "pointer" }}>
        {children}
      </span>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        data-bs-theme="dark"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{description}</Modal.Body>
        <Modal.Footer>
          {trueText && (
            <Button variant="success" onClick={handleTrue}>
              {trueText}
            </Button>
          )}
          {falseText && (
            <Button variant="danger" onClick={handleFalse}>
              {falseText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </span>
  );
};

export default BooleanModal;
