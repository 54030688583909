export const userColors = [
  "#00FFFF", // Светло-голубой
  "#FFA500", // Оранжевый
  "#FFFF00", // Лимонный желтый
  "#DDA0DD", // Светло-фиолетовый
  "#007FFF", // Лазурный
  "#FF00FF", // Неоновый розовый
  "#8A2BE2", // Синий фиолетовый
  "#FF1493", // Глубокий розовый
  "#FFD700", // Золотистый
  "#FFFFFF"  // Белый
];