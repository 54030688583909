import React, { useEffect } from "react";

import { Router } from "./router";
import useUserStore from "./store/useUserStore";

import "./styles/App.css";

export default function App() {
  const { fetchUserProfile } = useUserStore();

  useEffect(() => {
    document.body.classList.add("dark");
  }, []);

  useEffect(() => {
    fetchUserProfile();
    const intervalFetchUser = setInterval(() => {
      fetchUserProfile();
    }, 10 * 1000);
    return () => clearInterval(intervalFetchUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App" data-bs-theme="dark">
      <Router />
    </div>
  );
}
