import OpenAI from "openai";
import { useState, useEffect } from "react";

import { useNotification } from "./useNotification";
import useUserStore from "../store/useUserStore";

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const useGPTChat = () => {
  const welcomeMessage = [{ role: "assistant", content: "Hello! How can I help you today?" }];

  const assistantId = process.env.REACT_APP_OPENAI_ASSISTANT_ID;
  const modelsList = ["gpt-4o", "gpt-4", "gpt-3", "gpt-3-turbo"];
  const [model, setModel] = useState(process.env.REACT_APP_OPENAI_MODEL_ID?.toLowerCase() || modelsList[0]);
  const [chatHistory, setChatHistory] = useState();
  const [threadId, setThreadId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { showNotification } = useNotification();
  const { userProfile } = useUserStore();

  const createThread = async () => {
    const thread = await openai.beta.threads.create();
    setThreadId(thread.id);
  };

  const clearChat = () => {
    setChatHistory(welcomeMessage);
    createThread();
  };

  useEffect(() => {
    try {
      setChatHistory(JSON.parse(localStorage.getItem("chatHistory"))[userProfile.id] || welcomeMessage);
    } catch {
      setChatHistory(welcomeMessage);
    }

    if (!threadId) {
      createThread();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (threadId && chatHistory.length === 0 && userProfile?.displayName) {
      askGPT(`Hello! My name is ${userProfile?.displayName}.`);
    }
    // eslint-disable-next-line
  }, [threadId]);

  useEffect(() => {
    if (error) {
      showNotification({ message: error.toString() });
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (!modelsList.includes(model.toLowerCase())) {
      setModel(modelsList[0]);
    }
    // eslint-disable-next-line
  }, [model]);

  const clear = () => {
    setChatHistory([]);
    setLoading(false);
    setError(null);
  };

  const recognizeSpeech = async (recordedBlob) => {
    setLoading(true);

    const formData = new FormData();
    const fileName = `audio.${recordedBlob.type.split(";")[0].split("/")[1]}`;
    formData.append("file", recordedBlob, fileName, { type: recordedBlob.type });

    try {
      const response = await openai.audio.transcriptions.create({
        file: formData.get("file"),
        model: "whisper-1",
        response_format: "text",
      });
      return response;
    } catch (error) {
      showNotification({
        message: error.toString(),
        title: "Error during audio transcription:",
      });
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const askGPT = async (message, fileIds) => {
    if (loading || (!message && fileIds.length === 0)) {
      // eslint-disable-next-line
      showNotification({
        message: "Please wait for the end of the previous response or stop it.",
        // eslint-disable-next-line
      });
      return;
    }
    setLoading(true);
    setChatHistory([...chatHistory, { role: "user", content: message }]);

    try {
      await openai.beta.threads.messages.create(threadId, {
        role: "user",
        content: message,
        // tool_resources: {
        //   file_search: {
        //     vector_store_ids: [process.env.REACT_APP_OPENAI_FILE_VECTOR_STORE_ID],
        //   },
        // },
        attachments:
          fileIds?.length > 0
            ? fileIds.map((fid) => ({
                file_id: fid,
                tools: [{ type: "file_search" }],
              }))
            : [],
      });
      setChatHistory((prev) => [...prev, { role: "assistant", content: "" }]);

      openai.beta.threads.runs
        .stream(threadId, { assistant_id: assistantId })
        .on("textDelta", (delta, snapshot) => {
          setChatHistory((prev) => [
            ...prev.slice(0, prev.length - 1),
            {
              role: "assistant",
              content: snapshot.value,
            },
          ]);
        })
        .on("end", () => {
          setLoading(false);
        })
        // TODO: Check wehat happends
        .on("messageDone", async (event) => {
          if (event.content[0].type === "text") {
            const { text } = event.content[0];
            const { annotations } = text;
            const citations = [];

            let index = 0;
            for (let annotation of annotations) {
              text.value = text.value.replace(annotation.text, "[" + index + "]");
              const { file_citation } = annotation;
              if (file_citation) {
                const citedFile = await openai.files.retrieve(file_citation.file_id);
                citations.push("[" + index + "]" + citedFile.filename);
              }
              index++;
            }
          }
        });
    } catch (error) {
      setChatHistory((prev) => [...prev, { role: "assistant", content: error.toString() }]);
    }
    setLoading(false);
  };

  const removeFile = async (dileId) => {
    await openai.files.del(dileId);
  };

  const uploadFile = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await openai.files.create({
        file: formData.get("file"),
        purpose: "assistants",
      });
      return response.id;
    } catch (error) {
      showNotification({
        message: error.toString(),
        title: "Error during file upload:",
      });
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    askGPT,
    uploadFile,
    removeFile,
    recognizeSpeech,
    clear,
    chatHistory,
    setChatHistory,
    loading,
    setLoading,
    error,
    setModel,
    clearChat,
  };
};

export default useGPTChat;
