import React, { useState, useEffect, useMemo } from "react";
import useTickets from "../../hooks/useTickets";
import { useParams } from "react-router-dom";

const SingleTicket = () => {
  const { ticketId } = useParams();
  const { fetchTicket } = useTickets();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        setTicket(await fetchTicket(ticketId));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  const memoizedTicket = useMemo(() => ticket, [ticket]);

  if (loading || !memoizedTicket) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>{memoizedTicket.title}</h1>
      <p>{memoizedTicket.description}</p>
      <p>Priority: {memoizedTicket.priority}</p>
      <p>Status: {memoizedTicket.state}</p>
      <h2>Conversations:</h2>
      <ul>
        {memoizedTicket.conversations.map((conversation) => (
          <li key={conversation.id}>
            <strong>{conversation.userName}</strong>: {conversation.message}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SingleTicket;
