import React, { useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import logo from "../../static/images/logo.svg";
import useThemeStore from "../../store/themeStore";
import { Container, Stack, Tabs, Tab, Row, Col } from "react-bootstrap";

import styles from "./tools.module.css";
import InputField from "../InputField/InputField";

const QRGen = () => {
  const [qrCodeData, setQRCodeData] = useState("");
  const isWhiteMode = useThemeStore((state) => state.isWhiteMode);
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("AE Alternative Energy GMBH");
  const [position, setPosition] = useState("");
  const [website, setWebsite] = useState("https://ae-solar.com");

  const [activeTab, setActiveTab] = useState("vcard");

  const handleSelectTab = (k) => {
    setActiveTab(k);
  };

  useEffect(() => {
    if (activeTab === "url") {
      setQRCodeData(website);
      return;
    }
    setQRCodeData(
      `
BEGIN:VCARD
VERSION:3.0
FN:${fullName}
N:${fullName.split(" ")[1] || ""};${fullName.split(" ")[0] || ""};;;
TEL;TYPE=CELL:${phoneNumber}
EMAIL:${email}
ORG:${organization}
TITLE:${position}
URL:${website}
END:VCARD
  `
        .replace(/\n\s+/g, "\n")
        .trim()
    );
  }, [fullName, phoneNumber, email, organization, position, website, activeTab]);

  return (
    <Container fluid className="d-flex flex-column align-items-center gap-4">
      <Row className="pt-4">
        <h1>QR Code Generator</h1>
      </Row>
      <Row className={styles.wrapper}>
        <Col className={styles.colContent} xs={12} lg="auto">
          <Stack gap={1}>
            <Tabs
              className={isWhiteMode ? "" : styles.darkThemeTab}
              defaultActiveKey={activeTab}
              id="justify-tab"
              justify
              activeKey={activeTab}
              onSelect={handleSelectTab}
            >
              <Tab
                eventKey="url"
                title={
                  <span
                    className={`${styles.customTab} ${
                      activeTab === "url" ? (isWhiteMode ? styles.activeTab : styles.activeDarkThemeTab) : ""
                    } ${isWhiteMode ? "" : styles.darkThemeTab}`}
                  >
                    URL
                  </span>
                }
              >
                <Stack gap={3} className="pt-5">
                  <InputField
                    label="URL"
                    value={website}
                    icon="fa-globe"
                    onChange={(e) => setWebsite(e.target.value)}
                    placeholder="https://ae-solar.com"
                  />
                </Stack>
              </Tab>
              <Tab
                eventKey="vcard"
                title={
                  <span
                    className={`${styles.customTab} ${
                      activeTab === "vcard" ? (isWhiteMode ? styles.activeTab : styles.activeDarkThemeTab) : ""
                    } ${isWhiteMode ? "" : styles.darkThemeTab}`}
                  >
                    vCard
                  </span>
                }
              >
                <Stack gap={3} className="pt-5">
                  <InputField
                    label="Full name"
                    value={fullName}
                    icon="fa-user-large"
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder="Solar Panelin"
                  />
                  <InputField
                    label="Phone number"
                    value={phoneNumber}
                    icon="fa-phone"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="+49 123 456 7890"
                  />
                  <InputField
                    label="E-Mail"
                    value={email}
                    icon="fa-at"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="sale@ae-solar.com"
                  />
                  <InputField
                    label="Position"
                    value={position}
                    icon="fa-briefcase"
                    onChange={(e) => setPosition(e.target.value)}
                    placeholder="Sale manager"
                  />
                  <InputField
                    label="Website"
                    value={website}
                    icon="fa-globe"
                    onChange={(e) => setWebsite(e.target.value)}
                    placeholder="https://ae-solar.com"
                  />
                  <InputField
                    label="Organization"
                    value={organization}
                    icon="fa-copyright"
                    onChange={(e) => setOrganization(e.target.value)}
                    placeholder="AE Alternative Energy GMBH"
                  />
                </Stack>
              </Tab>
            </Tabs>
          </Stack>
        </Col>
        <Col className={styles.colFixed} xs={12} lg="auto">
          <QRCode
            value={qrCodeData}
            ecLevel="H"
            size={512}
            bgColor={isWhiteMode ? "#FFFFFF" : "#000000"}
            fgColor="rgba(246, 1, 9, 0.7)"
            logoImage={logo}
            logoWidth={128}
            removeQrCodeBehindLogo={true}
            logoPadding={16}
            logoPaddingStyle="circle"
            qrStyle="dots"
            eyeRadius={24}
            eyeColor="rgba(246, 1, 9, 0.4)"
            quietZone={16}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default QRGen;
