import React, { createContext, useContext, useState, useCallback } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import styles from "./index.module.css";
import { newUUID } from "../../tools";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [timeouts, setTimeouts] = useState(new Map());

  const showNotification = useCallback(({ title, type, message }) => {
    const id = newUUID();
    const newNotification = {
      id,
      title: title ?? "Notification",
      type: type ?? "error",
      message: message ?? "",
      show: true,
    };

    setNotifications((prevNotifications) => [...prevNotifications, newNotification]);

    const timeout = setTimeout(() => {
      setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
      setTimeouts((prevTimeouts) => {
        const newTimeouts = new Map(prevTimeouts);
        newTimeouts.delete(id);
        return newTimeouts;
      });
    }, 5000);

    setTimeouts((prevTimeouts) => {
      const newTimeouts = new Map(prevTimeouts);
      newTimeouts.set(id, timeout);
      return newTimeouts;
    });
  }, []);

  const handleMouseEnter = (id) => {
    if (timeouts.has(id)) {
      clearTimeout(timeouts.get(id));
      setTimeouts((prevTimeouts) => {
        const newTimeouts = new Map(prevTimeouts);
        newTimeouts.delete(id);
        return newTimeouts;
      });
    }
  };

  const handleMouseLeave = (id) => {
    const timeout = setTimeout(() => {
      setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
      setTimeouts((prevTimeouts) => {
        const newTimeouts = new Map(prevTimeouts);
        newTimeouts.delete(id);
        return newTimeouts;
      });
    }, 5000);

    setTimeouts((prevTimeouts) => {
      const newTimeouts = new Map(prevTimeouts);
      newTimeouts.set(id, timeout);
      return newTimeouts;
    });
  };

  const handleClose = (id) => {
    if (timeouts.has(id)) {
      clearTimeout(timeouts.get(id));
    }
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => (notification.id === id ? { ...notification, show: false } : notification))
    );

    setTimeout(() => {
      setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
      setTimeouts((prevTimeouts) => {
        const newTimeouts = new Map(prevTimeouts);
        newTimeouts.delete(id);
        return newTimeouts;
      });
    }, 300); // Задержка для анимации закрытия
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <ToastContainer position="top-end" className="p-3">
        {notifications.map((notification) => (
          <Toast
            key={notification.id}
            onClose={() => handleClose(notification.id)}
            show={notification.show}
            className={`${notification.show ? styles.notificationActive : ""} ${styles.notification} bg-dark text-light`}
            onMouseEnter={() => handleMouseEnter(notification.id)}
            onMouseLeave={() => handleMouseLeave(notification.id)}
          >
            <Toast.Header className={styles[notification.type]}>
              <strong className="me-auto">{notification.title}</strong>
            </Toast.Header>
            {notification.message && (
              <Toast.Body>
                {notification.message?.toString().split(/\n|<br>|<br \\>/).map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </Toast.Body>
            )}
          </Toast>
        ))}
      </ToastContainer>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
