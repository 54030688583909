import React from "react";
import { NavLink } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";

import { ProfileButton } from "../ProfileButton";

import logo from "../../static/images/logo_full.svg";
import styles from "./index.module.css";
import links from "../../router/links";
import packageJson from "../../../package.json";

export default function Header() {
  return (
    <Navbar className={`p-3 pt-1 pb-1 ${styles.navbar}`} fixed="top">
      <Navbar.Brand>
        <NavLink to={links.home}>
          <img src={logo} alt="AE SOLAR" />
          <span className={styles.subtitle}>Helpdesk v.{packageJson.version}</span>
        </NavLink>
      </Navbar.Brand>
      <div className="collapse navbar-collapse justify-content-end">
        <ProfileButton />
      </div>
    </Navbar>
  );
}
