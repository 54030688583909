import axios from "axios";

const apiRequest = async (endpoint, method = "GET", body = null) => {
  const requestMethod = method.toUpperCase() || "GET";

  if (!endpoint || endpoint.trim() === "") {
    return { error: "Empty endpoint" };
  }

  const options = {
    baseURL: process.env.REACT_APP_API_SERVER_URL,
    responseType: "json",
    timeout: 10000,
    headers: { "Content-Type": "application/json" },
  };

  let response = {};
  const instance = axios.create(options);

  try {
    response = await instance({
      method: requestMethod,
      url: process.env.REACT_APP_API_SERVER_URL + endpoint,
      data: body,
    });
  } catch (error) {
    response.error = error.message;
  } finally {
    if (response?.error) return { error: response.error };
  }
  return response.data;
};

export default apiRequest;
