import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import useUserStore from "../../store/useUserStore";

import Header from "../../components/Header";
import SideMemu from "../../components/SideMenu";
import useTickets from "../../hooks/useTickets";
import links from "../../router/links";

import LoginPage from "../LoginPage";
import AdminDashboardPage from "../AdminSectionPage";

import styles from "./index.module.css";

const PageLayout = () => {
  const location = useLocation();

  const { userProfile, ticketsOutdated } = useUserStore();
  const { fetchTickets } = useTickets();

  useEffect(() => {
    const fetchUserTickets = async () => {
      if (userProfile !== null) {
        await fetchTickets();
      }
    };

    if (location.pathname === links.tickets && userProfile?.id) {
      fetchUserTickets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, userProfile?.id, ticketsOutdated]);

  if (location.pathname.startsWith("/admin")) return <AdminDashboardPage />;
  else
    return userProfile || (!userProfile && location.pathname !== "/") ? (
      <div className="d-flex flex-column vh-100">
        <Header />
        <Container
          fluid
          className="flex-grow-1 d-flex flex-column p-0"
          style={{ marginTop: "60px" }}
        >
          <Row className="content p-0 m-0">
            <Col sm="auto" className="d-none d-md-block d-flex p-0 m-0">
              <SideMemu />
            </Col>
            <Col className={styles.content}>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </div>
    ) : (
      <LoginPage />
    );
};

export default PageLayout;
