import React from "react";
import style from "./index.module.css";

import useThemeStore from "../../store/themeStore";

export default function BrandButton(props) {
  const isWhiteMode = useThemeStore((state) => state.isWhiteMode);
  return (
    <button
      size={props.size || "md"}
      onClick={props.onClick}
      className={`${style.brandBtn} ${style[props.color ?? "primary"]} ${
        style[props.variant ?? "primary"]
      } ${isWhiteMode ? style.brandBtnWhite : ""} $
      ${props.className ?? ""}`}
      style={props.style ?? {}}
      type={props.type || "button"}
    >
      {props.children ?? ""}
    </button>
  );
}
