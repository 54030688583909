import React from "react";
import { useEffect, useState } from "react";
import Children from "react-children-utilities";
import { Container, Spinner, Table, Offcanvas } from "react-bootstrap";

import { cn } from "../../handlers/cn";
import useUserStore from "../../store/useUserStore";
import { getMyEmployees, getUserData } from "../../handlers/callMsGraph";

import styles from "./index.module.css";
import CopyableText from "../../components/CopyableText";
import TooltipText from "../../components/TooltipText";
import BooleanModal from "../../components/BooleanModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DetailRow = ({ label, value, icon, clipboard = false, copyText }) => {
  const valueText = Children.onlyText(value);
  clipboard = clipboard && (copyText || valueText);
  const valueSpan = <span className={styles.value}>{value}</span>;
  return (
    <p className={styles.fullRow}>
      {label}
      <span className={styles.gap10}>
        {clipboard ? <CopyableText copyText={copyText || valueText} /> : valueSpan}
        <span style={{ width: "1rem", height: "1rem" }}>
          {clipboard ? (
            <CopyableText copyText={copyText || valueText}>{icon || <FontAwesomeIcon icon="fa-solid fa-copy" />}</CopyableText>
          ) : (
            icon
          )}
        </span>
      </span>
    </p>
  );
};

const EployeeRow = ({ e }) => {
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState({});

  const handleDeactivateUser = async () => {
    console.log("Deactivate user", e.id);
  };

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    setUserData(await getUserData(e.id));
    setShow(true);
  };

  useEffect(() => {}, [userData]);

  return (
    <>
      <tr onClick={handleShow} className={cn(styles.row, e.accountEnabled ? styles.mlb : styles.pga)}>
        <td>{e.displayName}</td>
        <td>{e.userPrincipalName}</td>
        <td>{e.mobilePhone}</td>
        <td>{e.jobTitle}</td>
      </tr>
      <Offcanvas name={e.displayName} show={show} onHide={handleClose} placement="end" scroll={true} backdrop={true} data-bs-theme="dark">
        <Offcanvas.Header closeButton className={styles.offTitle}>
          <img src={userData.avatar} alt={userData.displayName} />
          <Offcanvas.Title>{userData.displayName}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <DetailRow
            label="User is"
            value={
              e.accountEnabled ? (
                <span className={styles.mlbText}>
                  <BooleanModal
                    trueText="Leave active"
                    falseText="Permanently block the user"
                    title="Do you want to disable this user?"
                    description={
                      <>
                        <p>
                          After you disable this user, they will no longer be able to log in, access their files, email, and other Microsoft
                          services.
                        </p>
                        <p>
                          <span style={{ color: "red" }}>Reactivation</span> is possible only by contacting the support service through the
                          Ticket system.
                        </p>
                      </>
                    }
                    onDecline={handleDeactivateUser}
                    onAccept={() => console.log("Leave active")}
                  >
                    <TooltipText tooltip="Click to block user">active</TooltipText>
                  </BooleanModal>
                </span>
              ) : (
                <span className={styles.pgaText}>
                  <TooltipText tooltip="User activation is possible only through the support ticket service">blocked</TooltipText>
                </span>
              )
            }
            icon={
              <span className={e.accountEnabled ? styles.mlbText : styles.pgaText}>
                <FontAwesomeIcon icon="fa-solid fa-circle" />
              </span>
            }
          />
          <DetailRow
            label="E-Mail"
            value={<TooltipText tooltip={userData.userPrincipalName}>{userData.mail || userData.userPrincipalName}</TooltipText>}
            clipboard={true}
          />
          <DetailRow label="Job Title" value={userData.jobTitle} />
          <DetailRow
            label="Work Phones"
            value={userData.businessPhones?.map((pb) => (
              <span key={pb}>{pb}</span>
            ))}
          />
          <DetailRow label="Mobile" value={userData.mobilePhone} clipboard={true} />
          <DetailRow
            label="Groups"
            value={userData.memberOf?.map((g) => (
              <TooltipText id={g.id} tooltip={g.description} key={g.id}>
                {g.displayName}
              </TooltipText>
            ))}
          />
          {userData.isAdmin && <DetailRow label="User is Admin" value="Yes" />}
          {userData.aboutMe && <span>{userData.aboutMe}</span>}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export const ManageEmployee = () => {
  const { userProfile } = useUserStore();
  const [myEmployees, setMyEmployees] = useState([]);

  useEffect(() => {
    const fetchMyEmployee = async () => {
      if (!userProfile) return;
      setMyEmployees(await getMyEmployees());
    };

    fetchMyEmployee();
  }, [userProfile]);

  return (
    <Container fluid>
      <h1>Manage Employees</h1>
      {myEmployees.length > 0 ? (
        <>
          <Table striped bordered hover responsive="sm" className={styles.table}>
            <thead>
              <tr className={styles.title}>
                <th className={styles.displayName}>Name</th>
                <th>E-Mail</th>
                <th className="">Phone</th>
                <th>Job Title</th>
              </tr>
            </thead>
            <tbody>
              {myEmployees.map((e) => (
                <EployeeRow e={e} key={e.id} />
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <Spinner />
      )}
    </Container>
  );
};
