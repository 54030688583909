import React, { useEffect, useState } from "react";

import InputField from "../../components/InputField/InputField";

import style from "./index.module.css";

import useUserStore from "../../store/useUserStore";

import { Container, Stack, Row, Col, Spinner } from "react-bootstrap";

const ProfilePage = () => {
  const { userProfile } = useUserStore();

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [chat, setChat] = useState('hello world');
  const [location, setLocation] = useState();
  const [jobTittle, setJobTittle] = useState();
  const [department, setDepartment] = useState();

  const [profileFilled, setProfileFilled] = useState(false);

  useEffect(() => {
    if(userProfile && !profileFilled) {
      setName(userProfile?.name)
      setEmail(userProfile?.email)
      setProfileFilled(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  const handleUpdateUserProfile = (e, tittle) => {
    console.log("Updating ", tittle)
  }

  if(!userProfile) {
    return (
      <Container fluid className="text-center p-5">
        <Spinner />
      </Container>
    );
  } else {
    return (
      <Container fluid className="d-flex flex-column align-items-center gap-4">
        <Row className="pt-4">
          <h1>Contact information</h1>
        </Row>
        <Row className={style.wrapper}>
          <Col className={style.colContent} xs={12} lg="auto">
            <Stack gap={3} className="pt-5">
              <InputField
                label="Name"
                value={name}
                icon="fa-signature"
                onChange={(e) => setName(e.target.value)}
                editable={(e) => {handleUpdateUserProfile(e, "UserName")}}
              />
              <InputField
                label="Phone number"
                value={phone}
                icon="fa-phone"
                onChange={(e) => setPhone(e.target.value)}
              />
              <InputField
                label="E-mail"
                value={email}
                icon="fa-envelope"
                onChange={(e) => setEmail(e.target.value)}
                editable={(e) => {handleUpdateUserProfile(e, "Email")}}
              />
              <InputField
                label="Chat"
                value={chat}
                icon="fa-message"
                onChange={(e) => setChat(e.target.value)}
              />
              <InputField
                label="Location"
                value={location}
                icon="fa-location-dot"
                onChange={(e) => setLocation(e.target.value)}
              />
              <InputField
                label="Job tittle"
                value={jobTittle}
                icon="fa-suitcase"
                onChange={(e) => setJobTittle(e.target.value)}
              />
              <InputField
                label="Department"
                value={department}
                icon="fa-building"
                onChange={(e) => setDepartment(e.target.value)}
              />
            </Stack>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default ProfilePage;
