// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../static/images/bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginPage_bg__AFTaY::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: -1;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center/cover;
}

h1 {
  font-size: 3rem;
}

h1 span {
  color: var(--brand-primary-color);
}

h2 {
  font-size: 2rem;
}

.LoginPage_logo__qS-g- {
  width: 300px;
  height: auto;
}

.LoginPage_onPlace__qzpbB {
  left: 0;
  right: 0;
  position: relative;
  transition: left 0.2s ease-in-out;
}

.LoginPage_leftSide__uOzZt {
  left: -100%;
}

.LoginPage_rightSide__gpflr {
  left: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,WAAW;EACX,iFAA2E;AAC7E;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,OAAO;EACP,QAAQ;EACR,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".bg::before {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  opacity: 0.6;\n  z-index: -1;\n  background: url(\"../../static/images/bg.svg\") no-repeat center center/cover;\n}\n\nh1 {\n  font-size: 3rem;\n}\n\nh1 span {\n  color: var(--brand-primary-color);\n}\n\nh2 {\n  font-size: 2rem;\n}\n\n.logo {\n  width: 300px;\n  height: auto;\n}\n\n.onPlace {\n  left: 0;\n  right: 0;\n  position: relative;\n  transition: left 0.2s ease-in-out;\n}\n\n.leftSide {\n  left: -100%;\n}\n\n.rightSide {\n  left: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": `LoginPage_bg__AFTaY`,
	"logo": `LoginPage_logo__qS-g-`,
	"onPlace": `LoginPage_onPlace__qzpbB`,
	"leftSide": `LoginPage_leftSide__uOzZt`,
	"rightSide": `LoginPage_rightSide__gpflr`
};
export default ___CSS_LOADER_EXPORT___;
