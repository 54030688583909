import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image, Stack, Container } from "react-bootstrap";
import logo from "../../static/images/logo_full.svg";
// import { useAccordionButton } from "react-bootstrap/AccordionButton";

import { MicrosoftLoginButton } from "../../components/MicrosoftLogin";

import styles from "./index.module.css";
import BrandButtonAnimated from "../../components/BrandButtonAnimated";
import LogoScreen from "../../components/LogoScreen";
import links from "../../router/links";
import useUserStore from "../../store/useUserStore";

// function LoginToggle() {
//   const decoratedOnClick = useAccordionButton("0");
//   return (
//     <BrandButtonAnimated onClick={decoratedOnClick} style={{ width: "300px" }}>
//       Login with credintials
//     </BrandButtonAnimated>
//   );
// }

const LoginPage = () => {
  const [showLogo, setShowLogo] = useState(true);
  const { userProfile } = useUserStore();

  const handleLogoFinish = () => {
    setShowLogo(false);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (userProfile) {
      setShowLogo(false);
    }
  }, [userProfile]);

  return (
    <>
      {!userProfile && showLogo && <LogoScreen onFinish={handleLogoFinish} />}
      <Container fluid className={`d-flex align-items-center min-vh-100 ${styles.bg}`}>
        <Row className="w-100">
          <Col
            className={`d-flex justify-content-center align-items-center my-auto ${styles.onPlace} ${showLogo && styles.leftSide}`}
            style={{ paddingBottom: "50px" }}
            md={{ span: 6, offset: 1 }}
          >
            <Stack gap={3} className="text-center text-md-start">
              <h1>{userProfile ? <>{userProfile.displayName}</> : null}</h1>
              <h1>
                <span>Welcome</span> to
              </h1>
              <h1>Online Support</h1>
              <Row className="justify-content-center justify-content-md-start">
                <Image src={logo} alt="AE SOLAR" className={styles.logo} />
              </Row>
            </Stack>
          </Col>
          <Col
            md={5}
            className={`my-auto d-flex justify-content-center align-items-center ${styles.onPlace} ${showLogo && styles.rightSide}`}
          >
            <Stack gap={3} className="align-items-center">
              {userProfile ? (
                <BrandButtonAnimated onClick={() => navigate(links.chat)} style={{ width: "300px" }}>
                  AI Chat Help
                </BrandButtonAnimated>
              ) : (
                <MicrosoftLoginButton style={{ width: "300px" }} />
              )}
              {/* <Accordion>
                <LoginToggle />
                <Accordion.Collapse eventKey="0">
                  <Form>
                    <Form.Group className="m-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>
                    <Form.Group className="m-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <BrandButtonAnimated type="submit">Login</BrandButtonAnimated>
                  </Form>
                </Accordion.Collapse>
              </Accordion> */}
              <BrandButtonAnimated onClick={() => navigate(links.helpcenter)} style={{ width: "300px" }}>
                Help Center
              </BrandButtonAnimated>
            </Stack>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
