import React from "react";
import remarkGfm from "remark-gfm";

import MarkdownContent from "../../MarkdownContent";
import Accordion from "../../Accordion";
import BrandLine from "../../BrandLine";

const HelpArticleItem = ({ article }) => {
  const { title, text, subtitle } = article;

  return (
    <Accordion
      title={title}
      subtitle={subtitle ?? `${text.substring(0, 55)}${text.length > 55 ? "..." : ""}`}
      style={{ margin: "1rem 0", background: "transparent" }}
    >
      <MarkdownContent remarkPlugins={[remarkGfm]}>{text}</MarkdownContent>
      <BrandLine style={{ marginTop: "1rem" }} />
    </Accordion>
  );
};

export default HelpArticleItem;
