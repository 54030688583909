import axios from "axios";

export const fetchArticles = async (
  slug,
  page = 1,
  pageSize = 10,
  searchString
) => {
  let searchFilter = "";
  if (searchString) {
    searchFilter = `&filters[$or][0][title][$containsi]=${searchString}`;
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_API_URL}/articles?populate=*${searchFilter}&filters[program][slug]=${slug}&pagination[pageSize]=${pageSize}&pagination[page]=${page}`
    );
    return response.data;
  } catch (err) {
    console.log("Error:", err);
  }
  return [];
};

export const fetchSections = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_STRAPI_API_URL}/sections?populate=*&filters[show]=true&sort=id:asc`
    );
    return response.data.data;
  } catch (err) {
    console.error("Error:", err);
  }
};
