import React from 'react'

const BrandLine = ({reverse=false, style={}}) => {
  return (
    <div style={{
        borderBottom: "2px solid transparent",
        borderImage: `linear-gradient(to ${reverse ? "left" : "right"}, var(--brand-error-color), var(--brand-background))`,
        borderImageSlice: "1",
        borderWidth: "1px",
        width: "100%",
        height: "1px",
        ...style
    }} />
  )
}

export default BrandLine;