import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "./static/fonts/GTWalsheimPro/GTWalsheimPro.css";
import "./static/fonts/CriteriaCF/CriteriaCF.css";
import "./styles/index.css";

import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./configs/authConfig";
import App from "./App";
import { NotificationProvider } from "./hooks/useNotification";
import { CookiesProvider } from "react-cookie";

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <NotificationProvider>
    <BrowserRouter>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <MsalProvider instance={msalInstance}>
          <StrictMode>
            <App />
          </StrictMode>
        </MsalProvider>
      </CookiesProvider>
    </BrowserRouter>
  </NotificationProvider>
);
