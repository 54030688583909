import React, { useEffect, useRef, useState } from "react";
import { Accordion, Col, Form, Row, useAccordionButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

import { dateToString } from "../../tools";
import useTickets from "../../hooks/useTickets";
import useUserStore from "../../store/useUserStore";

import styles from "./tickets.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priorityList, PrioritySelector } from "../ui/PrioritySelector";

const TicketItem = ({ eventKey, tk: initialTk, showHistory }) => {
  const { userProfile, userTickets, setUserTickets } = useUserStore();
  const [tk, setTk] = useState(initialTk);
  const [message, setMessage] = useState("");
  const AccordionOnClick = useAccordionButton(eventKey);
  const { closeTicket, addTicketAnswer, updatePriority } = useTickets();
  // const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [tk.conversations]);

  // const handleUploadFile = async (e) => {
  //   e.preventDefault();
  //   setFiles([...files, "asd"]);
  //   // const file = e.target.files[0];
  //   // const formData = new FormData();
  //   // formData.append("file", file);
  //   // const response = await fetch("/api/upload", {
  //   //   method: "POST",
  //   //   body: formData,
  //   // });
  //   // const data = await response.json();
  //   // console.log(data);
  // };

  const handleAnswerSubmit = async (e) => {
    e.preventDefault();
    await addTicketAnswer(tk.id, message);
    setTk((prevTk) => ({
      ...prevTk,
      conversations: [
        ...prevTk.conversations,
        {
          createdAt: Date.now(),
          id: prevTk.conversations.at(-1).id + 1,
          message,
          user: {
            name: userProfile.name,
            role: {
              name: userProfile.role,
            },
            uuid: userProfile.id,
          },
          files: [],
        },
      ],
    }));
    setMessage("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && (e.ctrlKey || e.shiftKey || e.altKey)) return;
    else if (e.key === "Enter") {
      e.preventDefault();
      handleAnswerSubmit(e);
    }
  };

  const handlePriorityChange = async (newPriority) => {
    updatePriority(tk.id, newPriority);
    setTk((prevTk) => ({
      ...prevTk,
      priority: newPriority,
    }));
  };

  const handleTicketClose = (e) => {
    e.stopPropagation();
    const newSet = userTickets.map((ticket) => (ticket.id === tk.id ? { ...ticket, state: "cancelled" } : ticket));
    setUserTickets(newSet);
    closeTicket(tk.id);
  };

  return (
    <Accordion.Item
      eventKey={tk.id}
      key={tk.id}
      className={`${styles.item} ${showHistory ? styles.closed : ""}`}
      // onClick={AccordionOnClick}
    >
      <Row className={styles.header} onClick={AccordionOnClick}>
        <Col md={10} className="d-flex align-items-start">
          <span role="button" className="d-flex align-items-center gap-2 text-start">
            <FontAwesomeIcon
              icon="fa-solid fa-window-restore"
              className={styles.ticketIcon}
              style={{
                color: priorityList.find((pr) => pr.name === tk.priority).color || "var(--brand-primary-color)",
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/tickets/${tk.id}`);
              }}
            />
            <Row className="m-0 p-0">
              {tk.title || "Untitled"}
              <span className={styles.date}>{dateToString(tk.createdAt)}</span>
            </Row>
          </span>
        </Col>
      </Row>
      <Accordion.Body
        className={`${styles.itemBody} ${styles.itemBodyWhite}`}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Row className="w-100">
          <Col className="col-9 d-flex flex-column gap-3 overflow-y-auto" style={{ maxHeight: "480px" }}>
            {tk.conversations?.map((msg) => {
              const isUser = userProfile.id === msg.user.uuid;
              const isAdminStar = msg.user.role.name === "admin" && (
                <FontAwesomeIcon icon="fa-solid fa-star" size="xs" style={{ color: "var(--brand-warning-color)" }} />
              );
              return (
                <div key={msg.id} className="d-flex flex-column flex-start position-relative">
                  <span
                    className={`${isUser ? "text-start" : "text-end"}`}
                    style={{
                      color: isUser ? "var(--brand-success-color)" : "var(--brand-warning-color)",
                    }}
                  >
                    {!isUser && isAdminStar} {(isUser && userProfile.name) || msg.user.name || msg.user.uuid} {isUser && isAdminStar}
                  </span>
                  <span
                    className={`${isUser ? "text-start" : "text-end"} text-nowrap text-start p-0 m-0`}
                    style={{ fontSize: "0.6rem", letterSpacing: "1px" }}
                  >
                    {dateToString(msg.createdAt)}
                  </span>
                  <div
                    className={`text-monospace ${isUser ? "text-start" : "text-end"}`}
                    style={{
                      whiteSpace: "break-spaces",
                    }}
                  >
                    {msg.message || "No message"}
                  </div>
                </div>
              );
            })}
            <div ref={messagesEndRef} />
          </Col>
          {!showHistory && (
            <Col
              className="col-3 d-flex flex-column justify-content-start align-items-center p-2 gap-2"
              style={{
                backgroundColor: "rgba(255,255,255,0.1)",
              }}
            >
              <PrioritySelector
                defaultPriority={tk.priority}
                onChange={handlePriorityChange}
                style={{
                  width: "150px",
                  fontSize: "0.8rem",
                  padding: "5px 25px",
                }}
              />
              <div
                className={styles.closeBtn}
                onClick={handleTicketClose}
                style={{ width: "150px", fontSize: "0.8rem", padding: "5px 25px" }}
              >
                {showHistory ? tk.state : "Close ticket"}
              </div>
            </Col>
          )}
        </Row>
        {!showHistory && (
          <>
            <Form onSubmit={handleAnswerSubmit} style={{ width: "100%" }}>
              <Form.Group
                style={{
                  marginTop: "1rem",
                  position: "relative",
                }}
                controlId="formBasicMessage"
              >
                <TextareaAutosize
                  className={`form-control ${styles.textarea}`}
                  minRows={1}
                  placeholder="Text message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={handleKeyPress}
                  required
                />
                {/* ! Temporary disabled */}
                {/* <button className={styles.Paperclip} onClick={handleUploadFile}>
                  <FontAwesomeIcon icon="fa-solid fa-paperclip"
                    style={{
                      color: files.length > 0 ? "var(--brand-success-color)" : "",
                    }}
                  />
                  {files.length > 0 && (
                    <span
                      className={styles.fileCount}
                      style={{
                        color: files.length > 0 ? "var(--brand-success-color)" : "",
                      }}
                    >
                      {files.length}
                    </span>
                  )}
                </button> */}
                <button className={styles.sendBtn} onClick={(e) => e.stopPropagation()} type="submit">
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                </button>
              </Form.Group>
            </Form>
          </>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default TicketItem;
