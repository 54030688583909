import React, { useState } from "react";
import { Container, Stack, Row, Col } from "react-bootstrap";

import useUserStore from "../../store/useUserStore";
import useTickets from "../../hooks/useTickets";
import { useNotification } from "../../hooks/useNotification";

import { useNavigate } from "react-router-dom";
import links from "../../router/links";

import styles from "./tools.module.css";
import InputField from "../InputField/InputField";
import BrandButton from "../BrandButton";

const AccountCreationForm = () => {
  const { showNotification } = useNotification();
  const { userProfile } = useUserStore();
  const { openTicket } = useTickets();

  const navigate = useNavigate();

  const [fullName, setFullName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [officeLocation, setOfficeLocation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [department, setDepartment] = useState("");
  const [login, setLogin] = useState(""); // Desired Username
  const [email, setEmail] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [startDate, setStartDate] = useState("");
  const [additional, setAdditional] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const text = `
Full name: ${fullName},\n
Job tittle: ${jobTitle},\n
Office Location: ${officeLocation},\n
Phone: ${phoneNumber},\n
Department: ${department},\n
Username: ${login},\n
Email: ${email},\n
Supervisor: ${supervisor},\n
Start date: ${startDate},\n
Comment: ${additional},\n
    `;

    const newTicketData = {
      state: "new",
      title: `Creation request ${email} account`,
      text: text,
      userId: userProfile.id,
      priority: "high",
      conversation_history: [
        {
          timestamp: new Date(),
          senderId: userProfile.id,
          message: text,
          senderName: userProfile.displayName,
        },
      ],
    };
    const result = await openTicket(newTicketData);

    if (result?.error) {
      showNotification({
        title: "New ticket creation error",
        message: result.error,
      });
      return;
    }

    try {
      setFullName("");
      setJobTitle("");
      setOfficeLocation("");
      setPhoneNumber("");
      setDepartment("");
      setLogin("");
      setEmail("");
      setSupervisor("");
      setStartDate("");
      setAdditional("");
      showNotification({
        title: "New ticket created.",
        type: "success",
        message: "Your request has been sent",
      });
      navigate(links.tickets);
    } catch (error) {
      showNotification({
        title: "New ticket creation error",
        message: error,
      });
    }
  };

  return (
    <Container fluid className="d-flex flex-column align-items-center gap-4">
      <Row className="pt-4">
        <h1>Account Creation Form</h1>
      </Row>
      <Row className={styles.wrapper}>
        <Col className={styles.colContent} xs={12} lg="auto">
          <Stack gap={3} className="pt-5">
            <InputField
              label="Full name"
              value={fullName}
              icon="fa-address-card"
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Solar Panelin"
            />
            <InputField
              label="Job tittle"
              value={jobTitle}
              icon="fa-user-tie"
              onChange={(e) => setJobTitle(e.target.value)}
              placeholder="SMM manager"
            />
            <InputField
              label="Office location"
              value={officeLocation}
              icon="fa-building"
              onChange={(e) => setOfficeLocation(e.target.value)}
              placeholder="City, Country"
            />
            <InputField
              label="Phone number"
              value={phoneNumber}
              icon="fa-mobile-screen-button"
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="+49 123 456 7890"
            />
            <InputField
              label="Department"
              value={department}
              icon="fa-building-user"
              onChange={(e) => setDepartment(e.target.value)}
              placeholder="Marketing Department"
            />
            <InputField
              label="Username"
              value={login}
              icon="fa-user"
              onChange={(e) => setLogin(e.target.value)}
              placeholder="j.doe@ae-solar.com"
            />
            <InputField
              label="E-Mail"
              value={email}
              icon="fa-at"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="sale@ae-solar.com"
            />
            <InputField
              label="Supervisor"
              value={supervisor}
              icon="fa-user-tie"
              onChange={(e) => setSupervisor(e.target.value)}
              placeholder="Waldemar Maier"
            />
            <InputField
              label="Start Date"
              value={startDate}
              icon="fa-calendar-day"
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="24.02.2022"
            />
            <InputField
              label="Additional Comments or Requests"
              value={additional}
              icon="fa-comment-medical"
              onChange={(e) => setAdditional(e.target.value)}
              placeholder="Your comments"
            />
          </Stack>
        </Col>
      </Row>
      <Row className="pt-1">
        <BrandButton className={styles.createButton} onClick={handleSubmit}>
          Create a ticket
        </BrandButton>
      </Row>
    </Container>
  );
};

export default AccountCreationForm;
