import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TooltipText = ({ id, tooltip, children, ...props }) => {
  id = id || `${tooltip}-${Math.random()}`;
  return (
    <OverlayTrigger
      key={id}
      placement="top"
      overlay={<Tooltip id={`tooltip-${id}`}>{tooltip}</Tooltip>}
      {...props}
    >
      <span style={{ cursor: "help" }}>{children}</span>
    </OverlayTrigger>
  );
};

export default TooltipText;
