import React, { useState } from "react";

const CopyableText = ({ copyText, children, ...props }) => {
  const [copied, setCopied] = useState(false);
  const [clickPos, setClickPos] = useState({ x: 0, y: 0 });

  const handleClick = (e) => {
    setClickPos({ x: e.clientX, y: e.clientY });
    navigator.clipboard.writeText(copyText).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 700);
    });
  };

  return (
    <span
      onClick={handleClick}
      style={{ cursor: "pointer", position: "relative" }}
      {...props}
    >
      {children || copyText}
      {copied && (
        <span
          style={{
            position: "fixed",
            top: clickPos.y - 30 + "px",
            left: clickPos.x - 80 + "px",
            background: "gray",
            padding: "2px 5px",
            borderRadius: "3px",
            zIndex: 1,
          }}
        >
          Copied!
        </span>
      )}
    </span>
  );
};

export default CopyableText;
