import React, { useEffect, useState } from "react";
import { Container, Stack, Row, Col } from "react-bootstrap";

import styles from "./tools.module.css";
import InputField from "../InputField/InputField";
import SignatureGenTemplate from "./SignatureTemplate";

const SignatureGen = () => {
  const [signCode, setSignCode] = useState("");
  const [finalText, setFinalText] = useState("Respectfully");
  const [fullName, setFullName] = useState("");
  const [position, setPosition] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState(`AE Alternative Energy GmbH
Messerschmittring 54
86343 Konigsbrunn
Germany`);

  useEffect(() => {
    setSignCode(SignatureGenTemplate({ finalText, fullName, position, mobilePhone, workPhone, email, address }));
  }, [fullName, mobilePhone, email, position, workPhone, finalText, address]);

  return (
    <Container fluid className="d-flex flex-column align-items-center gap-4">
      <Row className="pt-4">
        <h1>Signature Generator</h1>
      </Row>
      <Row className={styles.wrapper}>
        <Col className={styles.colContent} xs={12} lg="auto">
          <Stack gap={3} className="pt-5">
            <InputField
              label="Final text"
              value={finalText}
              icon="fa-text-width"
              onChange={(e) => setFinalText(e.target.value)}
              placeholder="Respectfully"
            />
            <InputField
              label="Full name"
              value={fullName}
              icon="fa-address-card"
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Solar Panelin"
            />
            <InputField
              label="Position"
              value={position}
              icon="fa-user-tie"
              onChange={(e) => setPosition(e.target.value)}
              placeholder="Sale manager"
            />
            <InputField
              label="Phone number"
              value={mobilePhone}
              icon="fa-mobile-screen-button"
              onChange={(e) => setMobilePhone(e.target.value)}
              placeholder="+49 123 456 7890"
            />
            <InputField
              label="Work number"
              value={workPhone}
              icon="fa-phone"
              onChange={(e) => setWorkPhone(e.target.value)}
              placeholder="+49 123 456 7890"
            />
            <InputField
              label="E-Mail"
              value={email}
              icon="fa-at"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="sale@ae-solar.com"
            />
            <InputField
              extended={true}
              label="Address"
              value={address}
              icon="fa-location-crosshairs"
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
            />
          </Stack>
        </Col>
        <Col className={`${styles.colFixed} p-2`} xs={12} lg="auto">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "20px",
              backgroundColor: "#fff",
            }}
          >
            {signCode}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignatureGen;
