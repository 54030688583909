// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_content__HAUCi {
    max-height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Layout/index.module.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,SAAS;AACb","sourcesContent":[".content {\n    max-height: calc(100vh - 60px);\n    overflow-x: hidden;\n    overflow-y: scroll;\n    padding: 0;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Layout_content__HAUCi`
};
export default ___CSS_LOADER_EXPORT___;
