import useApi from "./useAPI";
import useUserStore from "../store/useUserStore";
import apiRequest from "../handlers/api";

const useTickets = () => {
  const { userProfile, setUserTickets } = useUserStore();
  const { loading: isloading } = useApi();

  const fetchTickets = async () => {
    try {
      const data = await apiRequest(`/tickets`, "GET");
      if (!data || data.error) {
        setUserTickets([]);
        return { error: data?.error || "Failed to fetch tickets" };
      }
      setUserTickets(data);
      return data;
    } catch (error) {
      console.error("Failed to fetch tickets:", error);
    }
  };

  const fetchTicket = async (ticketId) => {
    try {
      return await apiRequest(`/tickets/${ticketId}`, "GET");
    } catch (error) {
      console.error(`Failed to fetch ticket ${ticketId}:`, error);
    }
  };

  const performActionWithTicket = async (endpoint, method = "POST", body = {}) => {
    try {
      return await apiRequest(endpoint, method, body);
    } catch (error) {
      console.error(`Failed to perform action on ticket:`, error);
      return { error };
    }
  };

  const closeTicket = async (ticketId) => {
    const result = await performActionWithTicket(`/tickets/${ticketId}`, "DELETE");
    await fetchTickets();
    return result;
  };

  const openTicket = async (ticketData) => {
    const result = await performActionWithTicket("/tickets", "POST", ticketData);
    await fetchTickets();
    return result;
  };

  const addTicketAnswer = async (ticketId, message) => {
    const answerData = {
      message,
      userId: userProfile?.id,
      ticketId,
      userName: userProfile?.displayName,
    };
    return await performActionWithTicket(`/tickets/${ticketId}/answer`, "POST", answerData);
  };

  const updatePriority = async (ticketId, priority) => {
    return await performActionWithTicket(`/tickets/${ticketId}/priority`, "POST", { priority });
  };

  return {
    isloading,
    closeTicket,
    openTicket,
    fetchTicket,
    fetchTickets,
    addTicketAnswer,
    updatePriority,
  };
};

export default useTickets;
