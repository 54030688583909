import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../configs/authConfig.js";

import useUserStore from "../store/useUserStore.js";
import { useNotification } from "./useNotification/index.js";
import { getFullData } from "../handlers/callMsGraph.js";

const useUserData = () => {
  const { showNotification } = useNotification();
  const { userAccount, setUserAccount, userProfile, logout } = useUserStore();
  const { instance, accounts } = useMsal();

  const fetchCurentUser = async () => {
    if (userAccount?.length === 0 || userAccount?.localAccountId === userProfile?.id) return;
    try {
      return await getFullData();
    } catch (error) {
      showNotification({
        title: "User Profile fetching error",
        type: "error",
        message: error.message,
      });
    }
  };

  const switchUser = async (switchToAccount) => {
    if (userAccount?.homeAccountId === switchToAccount?.homeAccountId) {
      return;
    }
    instance.setActiveAccount(switchToAccount);
    await instance.acquireTokenSilent({
      ...loginRequest,
      account: switchToAccount,
    });
    setUserAccount(switchToAccount);
  };

  const loginUser = async ({ authWidnowType = "popup" }) => {
    if (authWidnowType === "redirect") {
      await instance
        .loginRedirect(loginRequest)
        .then((res) => {
          instance.setActiveAccount(res.account);
          setUserAccount(res.account);
        })
        .catch((e) => {
          showNotification({
            title: "Login Error",
            type: "error",
            message: e.message,
          });
        });
      return;
    }
    await instance
      .loginPopup(loginRequest)
      .then((res) => {
        instance.setActiveAccount(res.account);
        setUserAccount(res.account);
      })
      .catch((e) => {
        showNotification({
          title: "Login Error",
          type: "error",
          message: e.message,
        });
      });
  };

  return {
    accounts,
    loginUser,
    logoutUser: logout,
    switchUser,
    fetchCurentUser,
  };
};

export default useUserData;
