import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";

import styles from "./index.module.css";
import logo from "../../static/images/logo_full.svg";

const LogoScreen = ({ onFinish }) => {
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const timer0 = setTimeout(() => setFadeIn(true), 200);
    const timer1 = setTimeout(() => setFadeOut(true), 2700);
    const timer2 = setTimeout(onFinish, 3000);
    return () => {
      clearTimeout(timer0);
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [onFinish]);

  return (
    <div className={`${styles.logoScreen} ${fadeIn && styles.fadeIn} ${fadeOut && styles.fadeOut}`}>
      <Container className="d-flex justify-content-center align-items-center vh-100 vw-100">
        <Image src={logo} alt="Company Logo" className={styles.logo} />
      </Container>
    </div>
  );
};

export default LogoScreen;
