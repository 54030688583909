import { useState, useCallback } from "react";
import axios from "axios";
import { useNotification } from "./useNotification";

const useApi = () => {
  const { showNotification } = useNotification();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const createApiInstance = useCallback(() => {
    const options = {
      baseURL: process.env.REACT_APP_API_SERVER_URL,
      responseType: "json",
      responseEncoding: "utf8",
      timeout: 10000,
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      withCredentials: false,
    };
    return axios.create(options);
  }, []);

  const handleError = useCallback(
    (error, endpoint, method) => {
      const errorMessage = error?.response?.data?.message || error.message;
      showNotification({
        title: `API ${method} request error: `,
        message: `Endpoint: ${endpoint}<br>Message: ${errorMessage}`,
        type: "error",
      });
      setError(errorMessage);
      setLoading(false);
    },
    [showNotification]
  );

  const fetchData = useCallback(
    async (endpoint, body = null, method = "GET") => {
      setLoading(true);
      setError(null);

      const instance = createApiInstance();
      const options = {
        method: method.toUpperCase(),
        url: endpoint,
        data: body,
      };

      try {
        const response = await instance(options);
        setLoading(false);
        return response.data || {};
      } catch (error) {
        handleError(error, endpoint, method);
        return {};
      }
    },
    [createApiInstance, handleError]
  );

  return { fetchData, loading, error };
};

export default useApi;
