import React from "react";
import OpenAI from "openai";

import { useState, useEffect, useRef } from "react";

import styles from "./index.module.css";
import ai_logo from "../../static/images/ai_logo.png";
import chat_bg_dark from "../../static/images/chat_bg_dark.png";

import { Row, Col, Container, Form, InputGroup, Button, Image, Stack } from "react-bootstrap";
import useFocus from "../../hooks/useFocus";
// import VoiceRecorder from "../../components/VoiceRecorder";
import useUserStore from "../../store/useUserStore";
import useGPTChat from "../../hooks/useOpenAI";
import MarkdownContent from "../../components/MarkdownContent";
import BrandButton from "../../components/BrandButton";

import useTickets from "../../hooks/useTickets";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../hooks/useNotification";
import links from "../../router/links";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrioritySelector } from "../../components/ui/PrioritySelector";

export const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

const ChatGPTPage = () => {
  const { userProfile } = useUserStore();
  const { showNotification } = useNotification();
  const [promptInput, setPromptInput] = useState("");
  const [files, setFiles] = useState([]);
  const [promptRef, setPromptFocus] = useFocus();
  const topRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const bottomRef = useRef(null);
  const [nextRef, setNextRef] = useState("");
  const { chatHistory, clearChat, uploadFile, askGPT, loading } = useGPTChat();
  const chatContainerRef = useRef(null);
  const [isComponentTaller, setIsComponentTaller] = useState(false);
  const checkHeight = () => {
    const componentHeight = chatContainerRef?.current.scrollHeight || 0;
    const windowHeight = window.innerHeight - 120;
    setIsComponentTaller(componentHeight > windowHeight);
  };
  const [priority, setPriority] = useState("low");

  const navigate = useNavigate();

  useEffect(() => {
    const currentTopRef = topRef.current;
    const currentBottomRef = bottomRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === currentTopRef) {
              setNextRef("top");
            } else if (entry.target === currentBottomRef) {
              setNextRef("bottom");
            }
          }
        });
      },
      { threshold: [0.0, 1.0] }
    );

    if (currentTopRef) {
      observer.observe(currentTopRef);
    }

    if (currentBottomRef) {
      observer.observe(currentBottomRef);
    }

    const handleInput = () => {
      const textarea = promptRef.current;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;

      window.scrollTo(scrollLeft, scrollTop);
    };

    const textarea = promptRef.current;
    textarea.addEventListener("input", handleInput);

    handleInput();

    return () => {
      if (currentTopRef) {
        observer.unobserve(currentTopRef);
      }
      if (currentBottomRef) {
        observer.unobserve(currentBottomRef);
      }

      textarea.removeEventListener("input", handleInput);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(chatHistory) {
      let userHistory;
      try {
        userHistory = JSON.parse(localStorage.getItem('chatHistory')) || {};
      } catch {
        userHistory = {};
      }
      userHistory[userProfile?.id] = chatHistory;
      localStorage.setItem(`chatHistory`, JSON.stringify(userHistory));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatHistory]);

  useEffect(() => {
    scrollToBottomRef();
    checkHeight();
  }, [chatHistory]);

  useEffect(() => {
    if (!loading) {
      setPromptFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!promptInput.trim()) return;
    askGPT(promptInput, files);
    setPromptInput("");
    const textarea = promptRef.current;
    textarea.style.height = "auto";
    setFiles([]);
  };

  const handleClipClick = (event) => {
    hiddenFileInput.current.click();
  };

  const scrollToBottomRef = () => {
    topRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  const scrollToTopRef = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleFileSelect = async (event) => {
    const fileId = await uploadFile(event.target.files[0]);
    if (!files.includes(fileId)) {
      setFiles([...files, fileId]);
    }
    event.target.value = null;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && (event.ctrlKey || event.shiftKey || event.altKey)) return;
    else if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleRemoveFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setFiles([]);
  };

  const { openTicket } = useTickets();

  const handleCreateTicket = async () => {
    const newTicketData = {
      state: "new",
      title: chatHistory[1].content.slice(0, 64),
      text: chatHistory[1].content,
      userId: userProfile.id,
      priority: priority,
      conversation_history: chatHistory.map((e) => ({
        timestamp: new Date(),
        senderId: e.role === "user" ? userProfile.id : null,
        message: e.content,
        senderName: e.role === "user" ? userProfile.displayName : "AIBert",
      })),
    };
    const result = await openTicket(newTicketData);

    if (result?.error) {
      showNotification({
        title: "New ticket creation error",
        message: result.error,
      });
      return;
    }

    try {
      showNotification({
        title: "New ticket created.",
        type: "success",
        message: "Your request has been sent",
      });
      navigate(links.tickets);
    } catch (error) {
      showNotification({
        title: "New ticket creation error",
        message: error,
      });
    }
  };

  return (
    <Container fluid className={styles.chatBody} style={{ backgroundImage: chat_bg_dark }}>
      <Row className="flex-grow-1 overflow-x-hidden overflow-y-auto">
        <Col>
          <div ref={chatContainerRef} className={styles.chatContainer}>
            <div ref={bottomRef} />
            {chatHistory
              ?.filter((msg) => msg.role !== "system")
              .map((msg, index) => (
                <Stack key={index} gap={3} className={msg.role === "user" ? styles.userMessage : styles.assistantMessage}>
                  <Stack direction="horizontal" gap={3} className={msg.role === "user" ? "justify-content-start" : "justify-content-end"}>
                    <Image src={msg.role === "user" ? userProfile?.avatar ?? ai_logo : ai_logo} roundedCircle width="30" height="30" />{" "}
                    <span className={styles.messageRole}>
                      {msg.role === "user" ? userProfile?.name : process.env.REACT_APP_ASSISTENT_NAME}
                    </span>
                  </Stack>
                  <span
                    className={`${msg.content.length > 0 ? "text-start" : "text-end blockquote-footer"} text-wrap text-break text-justify`}
                  >
                    <MarkdownContent>{msg.content.length > 0 ? msg.content : "I'm thinking..."}</MarkdownContent>
                  </span>
                </Stack>
              ))}
            <div ref={topRef} />
          </div>
        </Col>
      </Row>
      <Row fluid="md">
        <Col className="d-flex justify-content-center pb-2">
          {chatHistory?.length >= 3 && chatHistory[chatHistory.length - 1]?.content?.length >= 64 && (
            <div className="d-flex align-items-center gap-2 ">
              <PrioritySelector
                onChange={setPriority}
                style={{
                  padding: "5px 25px",
                  minWidth: "120px",
                  fontSize: "1rem",
                  borderRadius: "50px",
                  borderWidth: "2px",
                }}
              />
              <BrandButton onClick={handleCreateTicket}> Create Ticket </BrandButton>
            </div>
          )}
        </Col>
      </Row>
      <Row fluid="md">
        <Col>
          <Form className={`${styles.inputGroup}`}>
            <input type="file" onChange={handleFileSelect} ref={hiddenFileInput} style={{ display: "none" }} disabled={loading} />
            <InputGroup className={`xs-2`} onClick={setPromptFocus}>
              <Button
                className={`${styles.fileButton} ${files.length > 0 && styles.uploaded}`}
                onClick={handleClipClick}
                disabled={loading}
              >
                {files.length > 0 ? <span className={styles.filesCount}>{files.length}</span> : ""}
                <FontAwesomeIcon icon="fa-solid fa-paperclip" size="1x" />
                {files.length > 0 && (
                  <FontAwesomeIcon icon="fa-solid fa-circle-xmark" size="1x" onClick={handleRemoveFile} className={styles.removeFile} />
                )}
              </Button>
              <Form.Control
                id="chat-input"
                required
                as="textarea"
                rows="1"
                value={promptInput}
                placeholder="Describe your problem here..."
                onChange={(e) => setPromptInput(e.target.value)}
                className={styles.chatInput}
                disabled={loading}
                ref={promptRef}
                onKeyDown={handleKeyPress}
              />
              <div className={styles["button-wrapper"]}>
                <FontAwesomeIcon icon="fa-solid fa-pen-to-square" size="1x" onClick={clearChat} />
                {loading ? (
                  <FontAwesomeIcon icon="fa-solid fa-circle-stop" size="1x" onClick={handleSubmit} />
                ) : (
                  <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" size="1x" onClick={handleSubmit} />
                )}
                {/* <VoiceRecorder onTranscription={(e) => setPromptInput(promptInput + e)} disabled={loading} /> */}
              </div>
              {isComponentTaller === true && (
                <Button
                  className={`${styles.scrollButton} ${styles.voiceButton}`}
                  bsPrefix="btn-scroll"
                  onClick={nextRef === "top" ? scrollToTopRef : scrollToBottomRef}
                >
                  {nextRef === "top" ? (
                    <FontAwesomeIcon icon="fa-solid fa-arrow-turn-up" />
                  ) : (
                    <FontAwesomeIcon icon="fa-solid fa-arrow-turn-down" />
                  )}
                </Button>
              )}
            </InputGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default ChatGPTPage;
