// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__58-Vf {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.style_iconPlaceholder__mHk\\+7 {
  width: 32px;
  height: 32px;
  background-color: transparent;
}

.style_inputContainer__5lg3s {
  display: flex;
  align-items: space-between;
  border: 2px solid #fff;
  border-radius: 50px;
  padding: 5px 20px;
  position: relative;
  width: 100%;
  background-color: #000;
  align-items: center;
}

.style_inputContainerWhiteMode__ST7A4 {
  border: 2px solid #000; /* Белый режим */
  background-color: #fff;
}

.style_label__Z7eve {
  position: absolute;
  top: -10px;
  left: 20px;
  background-color: #000;
  color: #fff;
  padding: 0 5px;
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.style_labelWhiteMode__NVD2a {
  background-color: #fff;
  color: #000;
}

.style_input__Jb69T {
  border: none;
  outline: none;
  flex: 1 1;
  font-size: 20px;
  color: #fff;
  background-color: #000;
  padding: 5px 0;
  font-family: Arial, sans-serif;
}

.style_inputWhiteMode__0G4bR {
  color: #000;
  background-color: #fff;
}

.style_input__Jb69T::placeholder {
  color: #fff;
  opacity: 0.4;
}

.style_inputWhiteMode__0G4bR::placeholder {
  color: #000;
}
`, "",{"version":3,"sources":["webpack://./src/components/InputField/style.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,0BAA0B;EAC1B,sBAAsB;EACtB,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB,EAAE,gBAAgB;EACxC,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,sBAAsB;EACtB,WAAW;EACX,cAAc;EACd,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,SAAO;EACP,eAAe;EACf,WAAW;EACX,sBAAsB;EACtB,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;AACb","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: left;\n  align-items: center;\n  width: 100%;\n  gap: 10px;\n}\n\n.iconPlaceholder {\n  width: 32px;\n  height: 32px;\n  background-color: transparent;\n}\n\n.inputContainer {\n  display: flex;\n  align-items: space-between;\n  border: 2px solid #fff;\n  border-radius: 50px;\n  padding: 5px 20px;\n  position: relative;\n  width: 100%;\n  background-color: #000;\n  align-items: center;\n}\n\n.inputContainerWhiteMode {\n  border: 2px solid #000; /* Белый режим */\n  background-color: #fff;\n}\n\n.label {\n  position: absolute;\n  top: -10px;\n  left: 20px;\n  background-color: #000;\n  color: #fff;\n  padding: 0 5px;\n  font-size: 14px;\n  font-family: Arial, sans-serif;\n}\n\n.labelWhiteMode {\n  background-color: #fff;\n  color: #000;\n}\n\n.input {\n  border: none;\n  outline: none;\n  flex: 1;\n  font-size: 20px;\n  color: #fff;\n  background-color: #000;\n  padding: 5px 0;\n  font-family: Arial, sans-serif;\n}\n\n.inputWhiteMode {\n  color: #000;\n  background-color: #fff;\n}\n\n.input::placeholder {\n  color: #fff;\n  opacity: 0.4;\n}\n\n.inputWhiteMode::placeholder {\n  color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__58-Vf`,
	"iconPlaceholder": `style_iconPlaceholder__mHk+7`,
	"inputContainer": `style_inputContainer__5lg3s`,
	"inputContainerWhiteMode": `style_inputContainerWhiteMode__ST7A4`,
	"label": `style_label__Z7eve`,
	"labelWhiteMode": `style_labelWhiteMode__NVD2a`,
	"input": `style_input__Jb69T`,
	"inputWhiteMode": `style_inputWhiteMode__0G4bR`
};
export default ___CSS_LOADER_EXPORT___;
