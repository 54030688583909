// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_navbar__pafCG {
    background-color: var(--brand-background);
    border-bottom: 2px solid transparent;
    /* border-image: linear-gradient(0.25turn, var(--bs-body-bg), var(--brand-primary-color)); */
    border-image: linear-gradient(to left, var(--brand-error-color), var(--brand-background));
    border-image-slice: 1;
    border-width: 1px;
    background-color: var(--brand-background);
    height: 60px;
}

.Header_navbar__pafCG span {
    color: var(--brand-text);
}

.Header_navbarDark__nlpfO {
    background-color: var(--brand-background);
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(0.25turn, var(--bs-body-bg), var(--brand-primary-color));
    border-image-slice: 1;
    border-width: 1px;
    height: 60px;
}

.Header_navbar__pafCG a {
    text-decoration: none;
    color: var(--brand-second-color);
}

body.Header_dark__qTrx5 .Header_navbar__pafCG a {
    text-decoration: none;
    color: var(--brand-background);
}

.Header_subtitle__MCewH {
    font-size: 14px !important;
    position: relative;
    top: -10px !important;
    padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/index.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,oCAAoC;IACpC,4FAA4F;IAC5F,yFAAyF;IACzF,qBAAqB;IACrB,iBAAiB;IACjB,yCAAyC;IACzC,YAAY;AAChB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yCAAyC;IACzC,oCAAoC;IACpC,sFAAsF;IACtF,qBAAqB;IACrB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;IACrB,8BAA8B;AAClC;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;AACtB","sourcesContent":[".navbar {\n    background-color: var(--brand-background);\n    border-bottom: 2px solid transparent;\n    /* border-image: linear-gradient(0.25turn, var(--bs-body-bg), var(--brand-primary-color)); */\n    border-image: linear-gradient(to left, var(--brand-error-color), var(--brand-background));\n    border-image-slice: 1;\n    border-width: 1px;\n    background-color: var(--brand-background);\n    height: 60px;\n}\n\n.navbar span {\n    color: var(--brand-text);\n}\n\n.navbarDark {\n    background-color: var(--brand-background);\n    border-bottom: 2px solid transparent;\n    border-image: linear-gradient(0.25turn, var(--bs-body-bg), var(--brand-primary-color));\n    border-image-slice: 1;\n    border-width: 1px;\n    height: 60px;\n}\n\n.navbar a {\n    text-decoration: none;\n    color: var(--brand-second-color);\n}\n\nbody.dark .navbar a {\n    text-decoration: none;\n    color: var(--brand-background);\n}\n\n.subtitle {\n    font-size: 14px !important;\n    position: relative;\n    top: -10px !important;\n    padding-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `Header_navbar__pafCG`,
	"navbarDark": `Header_navbarDark__nlpfO`,
	"dark": `Header_dark__qTrx5`,
	"subtitle": `Header_subtitle__MCewH`
};
export default ___CSS_LOADER_EXPORT___;
