import { loginRequest, graphConfig } from "../configs/authConfig.js";
import { msalInstance } from "../index.js";
import defaultAvatar from "../static/images/default_avatar.png";

const callMsGraph = async (endpoint, accessToken, data, method) => {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("ConsistencyLevel", "eventual");

  const options = {
    method: method ?? "GET",
    headers: headers,
  };
  if (data) {
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");
    options["method"] = method ?? "POST";
    options["headers"] = headers;
    options["body"] = JSON.stringify(data);
  }

  try {
    return await fetch(endpoint, options)
      .then((response) => {
        if (endpoint === graphConfig.graphAvatartEndpoint) {
          return response.blob();
        }
        return response.json();
      })
      .catch((error) => {
        return { error: error.toString() };
      });
  } catch (error) {
    throw Error(`Error fetching profile: ${error.message}`);
  }
};

const getFullData = async () => {
  const batchBody = {
    requests: [
      {
        id: "1",
        method: "GET",
        url: "/me?$select=displayName,id,jobTitle,mail,userPrincipalName,accountEnabled,department",
      },
      {
        id: "2",
        method: "GET",
        url: "/me/photo/$value",
      },
      {
        id: "3",
        method: "GET",
        url: "/me/memberOf/microsoft.graph.group?$select=displayName,id",
      },
      {
        id: "4",
        method: "GET",
        url: "/me/manager?$select=id,displayName,jobTitle,mail,userPrincipalName,officeLocation,mobilePhone,accountEnabled",
      },
      {
        id: "5",
        method: "GET",
        url: "/me/directReports?$select=id,displayName,jobTitle,mail,userPrincipalName,officeLocation,mobilePhone,accountEnabled,department",
      },
      {
        id: "6",
        method: "GET",
        url: "/me/planner/tasks",
      },
    ],
  };

  const resp = await callMsGraph(graphConfig.batch, null, batchBody);
  const me = resp?.responses?.find((r) => r.id === "1")?.body;
  const avatar = resp?.responses?.find((r) => r.id === "2")?.body;
  const groups = resp?.responses?.find((r) => r.id === "3")?.body?.value;
  const myEmployees = resp?.responses?.find((r) => r.id === "5")?.body?.value;
  const tasks = resp?.responses?.find((r) => r.id === "6")?.body?.value;
  const userData = {
    accountEnabled: me?.accountEnabled,
    department: me?.department,
    displayName: me?.displayName,
    id: me?.id,
    jobTitle: me?.jobTitle,
    mail: me?.mail,
    userPrincipalName: me?.userPrincipalName,
    isAdmin: groups?.some(
      (group) => group.id === process.env.REACT_APP_ADMIN_GROUP_ID
    ),
    avatar: avatar?.includes("eyJlcnJvciI6eyJjb2RlI")
      ? defaultAvatar
      : `data:image/jpeg;base64,${avatar}`,
    groups,
    myEmployees,
    tasks,
  };

  return userData;
};

// const getMyProfile = async () => {
//   return await callMsGraph(graphConfig.graphMeEndpoint);
// };

// const getMyAdminStatus = async () => {
//   const response = await callMsGraph(graphConfig.graphMemberOf);
//   return response.value.some(
//     (group) => group.id === process.env.REACT_APP_ADMIN_GROUP_ID
//   );
// };

// const getMyAvatar = async () => {
//   const blob = await callMsGraph(graphConfig.graphAvatartEndpoint);
//   return URL.createObjectURL(blob ?? (await fetch(defaultAvatar)).blob());
// };

// const getMyGroups = async () => {
//   return await callMsGraph(graphConfig.graphMemberOf).then((g) => g?.value);
// };

const getMyEmployees = async () => {
  return await callMsGraph(graphConfig.graphGetMyEmployees).then(
    (e) => e?.value
  );
};

const getUserData = async (userId) => {
  const batchBody = {
    requests: [
      {
        id: "1",
        method: "GET",
        url: `/users/${userId}?$expand=memberOf&$select=id,accountEnabled,assignedLicenses,assignedPlans,authorizationInfo,businessPhones,createdDateTime,department,displayName,employeeHireDate,employeeLeaveDateTime,jobTitle,mail,mobilePhone,preferredLanguage,userPrincipalName,birthday,hireDate,aboutMe`,
      },
      {
        id: "2",
        method: "GET",
        url: `/users/${userId}/photo/$value`,
      },
    ],
  };
  const resp = await callMsGraph(graphConfig.batch, null, batchBody);
  const userData = resp?.responses?.find((r) => r.id === "1")?.body;
  const avatar = resp?.responses?.find((r) => r.id === "2")?.body;
  return {
    ...userData,
    avatar: avatar.includes("eyJlcnJvciI6eyJjb2RlI")
      ? defaultAvatar
      : `data:image/jpeg;base64,${avatar}`,
  };
};

// const getMyFullData = async () => {
//   const profile = await getMyProfile();
//   profile.isAdmin = await getMyAdminStatus();
//   profile.avatar = await getMyAvatar();
//   profile.groups = await getMyGroups();
//   profile.myEmployees = await getMyEmployees();
//   return profile;
// };

export { getFullData, getMyEmployees, callMsGraph, getUserData };
