"use client";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import styles from "./index.module.css";

import useThemeStore from "../../store/themeStore";

const HoverBorderGradient = ({
  children,
  containerClassName,
  className,
  as: Tag = "button",
  duration = 1,
  clockwise = true,
  style = {},
  active,
  ...props
}) => {
  const isWhiteMode = useThemeStore((state) => state.isWhiteMode);
  const [hovered, setHovered] = useState(false);
  const [direction, setDirection] = useState("TOP");

  const rotateDirection = (currentDirection) => {
    const directions = ["TOP", "LEFT", "BOTTOM", "RIGHT"];
    const currentIndex = directions.indexOf(currentDirection);
    const nextIndex = clockwise
      ? (currentIndex - 1 + directions.length) % directions.length
      : (currentIndex + 1) % directions.length;
    return directions[nextIndex];
  };

  const movingMap = {
    TOP: "radial-gradient(100% 100% at 50% 0%, var(--brand-primary-color) 0%, rgba(255, 255, 255, 0) 100%)",
    LEFT: "radial-gradient(100% 100% at 0% 50%, var(--brand-primary-color) 0%, rgba(255, 255, 255, 0) 100%)",
    BOTTOM:
      "radial-gradient(100% 100% at 50% 100%, var(--brand-primary-color) 0%, rgba(255, 255, 255, 0) 100%)",
    RIGHT:
      "radial-gradient(100% 100% at 100% 50%, var(--brand-primary-color) 0%, rgba(255, 255, 255, 0) 100%)",
  };

  const highlight =
    "radial-gradient(100% 250% at 50% 50%, var(--brand-primary-color) 0%, rgba(255, 255, 255, 0) 100%)";

  useEffect(() => {
    if (!hovered && !active) {
      const interval = setInterval(() => {
        setDirection((prevState) => rotateDirection(prevState));
      }, duration * 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered, active]);

  return (
    <Tag
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className={`${styles.container} ${containerClassName} ${isWhiteMode && styles.containerWhite}` }
      style={style}
      {...props}
    >
      <div className={`${styles.content} ${className} ${ isWhiteMode ? styles.contentWhite : ""}`} style={style}>
        {children}
      </div>
      <motion.div
        className={styles.motionDiv}
        style={{ background: movingMap[direction] }}
        animate={{
          background: hovered || active
            ? [movingMap[direction], highlight]
            : movingMap[direction],
        }}
        transition={{ ease: "linear", duration: duration ?? 1 }}
      />
      <div className={`${styles.innerBorder} ${ isWhiteMode ? styles.innerBorderWhite : ""}`} />
    </Tag>
  );
};

export default HoverBorderGradient;
