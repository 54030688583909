import React from "react";
import { useNavigate } from "react-router-dom";

import BrandButton from "../../BrandButton";

const HelpAppItem = ({ app }) => {
  const navigate = useNavigate();

  return (
    <BrandButton onClick={() => navigate(`${app.slug}`)} className="w-100 ">
      {app.Name}
    </BrandButton>
  );
};

export default HelpAppItem;
